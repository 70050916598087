import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, Typography, AppBar, Toolbar, Button, Select, MenuItem, InputLabel, FormControl, OutlinedInput, Chip } from '@mui/material';
import JsonPretty from 'react-json-pretty';
import axios from 'axios';
import PopupForm from './PopupForm';
import { Toaster, toast } from 'react-hot-toast';
import { grey } from '@mui/material/colors';

function TableWithApiData() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(true);

  useEffect(() => {
    const filtered = data.filter(row => selectedCategories.length === 0 || selectedCategories.includes(row.UpdateType));
    setFilteredData(filtered);
  }, [data, selectedCategories]);

  const showErrorToast = (error) => {
    toast.error((t) => (
      <div>
        <p>Error Encountered!</p> {/* This acts as the title */}
        <strong>----- {error.response?.data} -----</strong>
        <p>Error message: {error.message}</p>
      </div>
    ));
  };
  const fetchTableData = (chatUserPhoneId, companyId) => {
    axios.get(`https://history.funner.co.il/api/services/getChatUserBotHistory?chatUserPhoneId=${chatUserPhoneId}&companyId=${companyId}`)
      .then(response => {
        setData(response.data);
        setPopupOpen(false); // Close popup after fetching data
        toast.success('Data fetched successfully!');
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        showErrorToast(error);
        setPopupOpen(false); // Close popup even on error
      });
  };

  const handleRefreshClick = () => {
    setPopupOpen(true); // Reopen the popup form
  };

  const handleClickOpen = (data) => {
    setOpen(true);
    setSelectedData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCategory = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
  };
  var backgroundColor = "green";
  return (
    <div>
      {popupOpen && <PopupForm onSubmit={fetchTableData} />}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Data Viewer
          </Typography>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="category-mutiple-chip-label">Filter Categories</InputLabel>
            <Select
              labelId="category-mutiple-chip-label"
              multiple
              value={selectedCategories}
              onChange={handleChangeCategory}
              input={<OutlinedInput id="select-multiple-chip" label="Filter Categories" />}
              renderValue={(selected) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} style={{ margin: 2 }} />
                  ))}
                </div>
              )}
            >
              {['SaveBotLog', 'actionResult', 'generalErrors'].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button color="inherit" onClick={handleRefreshClick}>
            Refresh Data
          </Button>
        </Toolbar>
      </AppBar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Run Id</TableCell>
              <TableCell>BotName</TableCell>
              <TableCell>StepName</TableCell>
              <TableCell>UpdateDate</TableCell>
              <TableCell>Category</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(() => {
              let previousFlowId = null; // To keep track of the previous FlowId
              let backgroundColor = '#1976d214'; // Default background color
              return filteredData.map((row, index) => {
                // Check if the current FlowId is different from the previous one
                if (row.FlowId > 0) {
                  if (row.FlowId !== previousFlowId) {
                    // Toggle the background color
                    backgroundColor = backgroundColor === '#1976d214' ? 'white' : '#1976d214';                    
                  }
                  previousFlowId = row.FlowId;
                }
                
                // Update previousFlowId for the next iteration
                

                return (
                  <TableRow key={index} hover onClick={() => handleClickOpen(row)} style={{ background: backgroundColor }}>
                    <TableCell>{row.FlowId}</TableCell>
                    <TableCell>{row.BotName}</TableCell>
                    <TableCell>{row.StepName}</TableCell>
                    <TableCell>{row.UpdateDate}</TableCell>
                    <TableCell>{row.UpdateType}</TableCell>
                  </TableRow>
                );
              });
            })()}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <Typography variant="h6" component="div" style={{ marginTop: 20 }}>
            Bot Step Object
          </Typography>
          <Typography component={'span'}>
            <JsonPretty id="json-pretty-chat-user" data={selectedData != null ? selectedData : {}}></JsonPretty>
          </Typography>
          <Typography variant="h6" component="div" style={{ marginTop: 20 }}>
            Chat User Object
          </Typography>
          <Typography component={'span'}>
            <JsonPretty id="json-pretty-chat-user" data={selectedData.ChatUserObj != null ? JSON.parse(selectedData.ChatUserObj) : {}}></JsonPretty>
          </Typography>
          <Typography variant="h6" component="div" style={{ marginTop: 20 }}>
            Message Object
          </Typography>
          <Typography component={'span'}>
            <JsonPretty id="json-pretty-message" data={selectedData.MessageObj != null ? JSON.parse(selectedData.MessageObj) : {}}></JsonPretty>
          </Typography>
          <Typography variant="h6" component="div" style={{ marginTop: 20 }}>
            ActionObject {selectedData.ActionName}
          </Typography>
          <Typography component={'span'}>
            <JsonPretty id="json-pretty-message" data={selectedData.ActionData != null ? JSON.parse(selectedData.ActionData) : {}}></JsonPretty>
          </Typography>
        </DialogContent>
      </Dialog>
      <Toaster />
    </div>
  );
}

function App() {
  return <TableWithApiData />;
}

export default App;
